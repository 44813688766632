import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  GET_ALARMS_OBJECT
} from '@/store/actions/maps'
import {
  successGetAlarmsObject
} from '@/store/actions/maps/getAlarmsObject'
import { ALARM_MANAGER_TABLE_ENDPOINT } from '@/constants/apiRoutes'
import { errorAlarmManagerTable } from '@/store/actions/alarmManager/getTable'
import { getSelectedNode } from '@/store/selectors/appSettings'
import createNotifications from '@/helpers/notification'
import { INSTALLED, UNINSTALLED } from '@/constants/objectStatuses'
import { setGlobalLoading } from '@/store/actions/appSettings'


function* getAlarmsObjectSaga() {
  try {
    console.log('getAlarmsObjectSaga')
    const node = yield select(getSelectedNode)

    const response = yield request({
      url: ALARM_MANAGER_TABLE_ENDPOINT({
        params: {
          objectStates: [INSTALLED, UNINSTALLED],
          alertTypes: ['ERROR', 'WARNING'],
          pageNumber: 0,
          pageSize: 20,
          parentId: node.id,
        },
      }),
      method: 'get',
    })

    const {
      content,
    } = response.data
    yield put(setGlobalLoading(false))
    yield put(successGetAlarmsObject(content))

  } catch (error) {
    yield put(errorAlarmManagerTable(error))
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
  return null
}

export default function* root() {
  yield takeLatest(GET_ALARMS_OBJECT.REQUEST, getAlarmsObjectSaga)
}
