import React, { useCallback, useEffect, useState } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { telemetryArrayProps } from '@/propTypes/maps'
import useHtmlTitle from '@/hooks/useHtmlTitle'
import useReduxTable from '@/hooks/useReduxTable'
import Loader from '@/components/blocks/Loader'
import DutyTable from '@/components/blocks/DutyTable'
import { REQUEST_STATUSES } from '@/constants/requests'
import { ALARM_MANAGER_TABLE } from '@/store/actions/alarmManager'
import { getTableParameters } from '@/store/selectors/alarmManager'
import {
  fields,
  exportOptions,
  eventsOptions,
  settingsOptions,
} from '@/constants/tablesConfig/alarmManager'
import AlarmSideBar from './components/AlarmSideBar'
import {
  Main,
  Content,
} from './styles'
import TreeSidebar from '@/containers/pages/Installation/components/TreeSidebar'

const AlarmsManager = ({
  data,
  node,
  tableStatus,
  exportFileStatus,
  selectedElement,
  aliasId,
  eventData,
  loadingStatistic,
  loadingTable,
  isAlarms,
  card,
  globalLoading,

  setNode,
  setSelectedElement,
  requestAlarmManagerTable,
  requestAlarmManagerFile,
  setSelectedAllElements,
  requestTableStatistics,
  intl,
  requestGetAlertFieldsOptions,
  fieldOptions,
  filterValues,
  setAlarmsFilterValues,
  status,
  refreshRate
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const [isDataRequested, setIsDataRequested] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  
  useHtmlTitle(intl.messages['menu.alarms'])

  const { eventTypes, eventStatus, dates, descriptions, activeFields } = filterValues
  const dateRange = {start: dates.start ? new Date(dates.start) : null, end: dates.end ? new Date(dates.end) : null}

  useEffect(() => {
    requestAlarmManagerTable()
    const intervalId = setInterval(() => {
      requestAlarmManagerTable()
      requestTableStatistics()
      setIsDataRequested(true)
    }, refreshRate * 1000)
    return () => {
      clearInterval(intervalId)
    }
  },[
    requestAlarmManagerTable,
    requestTableStatistics,
    refreshRate,
  ])

  useEffect(() => {
    setSelectedRow(selectedElement)

  }, [selectedElement, setSelectedRow])

  const onRowSelect = (row) => {
    setSelectedElement(row)
  }
  useEffect(() => {
    if (!isDataRequested && status === 'IDLE') {
      requestGetAlertFieldsOptions(filterValues)
    }

  },[
    requestGetAlertFieldsOptions,
    status,
    filterValues,
    isDataRequested,
    node,
  ])

  const tableProps = useReduxTable(ALARM_MANAGER_TABLE, getTableParameters)

  const setFilterValues = (value) => {
    setAlarmsFilterValues({...filterValues, eventTypes: value})
  }

  const setStatus = (value) => {
    setAlarmsFilterValues({...filterValues, eventStatus: value})
  }

  const setDatesRangeTest = (value) => {
    setAlarmsFilterValues({...filterValues, dates: value})
  }

  const setSelectedFields = (value) => {
    setAlarmsFilterValues({...filterValues, activeFields: value})
  }

  const handleReadAllAlarms = useCallback(() => {
    setSelectedAllElements({ id: node.id })
  }, [node.id, setSelectedAllElements])

  const onSelectNode = useCallback((selectedNode, event) => {
    if (event === 'click') {
      setNode(selectedNode)
      requestAlarmManagerTable()
      requestTableStatistics()
    }
  },
  [setNode, requestAlarmManagerTable, requestTableStatistics])

  const handleFilterColumn = (value) => {
    setAlarmsFilterValues({...filterValues, descriptions: value})
  }
  
  return (
    <Main>
      <Content>
        <TreeSidebar
          title={intl.messages['menu.alarms']}
          onSelect={onSelectNode}
          hidden={false}
          selectedNode={node}
        />
        <AlarmSideBar
          selectedNodeName={node.name}
          selectedElement={selectedElement}
          setSelectedElement={setSelectedElement}
          setNode={setNode}
          setEventTypes={setFilterValues}
          filterValues={filterValues}
          eventsOptions={eventsOptions}
          isOpen={isOpen}
          toggleIsOpen={setIsOpen}
          eventTypes={eventTypes}
          aliasId={aliasId}
          eventsCount={tableProps.values.resultCount}
          eventData={eventData}
          isTableLoaded={tableStatus === REQUEST_STATUSES.IDLE}
          dates={dateRange}
          loadingStatistic={loadingStatistic}
          card={card.data}
        />
        {loadingTable
          ? <Loader center />
          : (
            <DutyTable
              withDates
              fields={fields}
              dates={dateRange}
              exportOptions={exportOptions}
              eventsOptions={eventsOptions}
              settingsOptions={settingsOptions}
              getTableData={setDatesRangeTest}
              handleReadAllAlarms={handleReadAllAlarms}
              setSelectedFieldsActive={setSelectedFields}
              isAlarms={isAlarms}
              fieldOptions={fieldOptions}
              descriptions={descriptions}
              handleFilterColumn={handleFilterColumn}
              formatDate={'dd.MM.yyyy HH:mm'}
              showTimeSelect
              formatWithTime
              activeFields={activeFields}

              {...tableProps}

              eventStatus={ eventStatus }
              eventTypes={ eventTypes }

              data={data}
              rowSelector={onRowSelect}
              selectedRow={selectedRow}

              setEventTypes={setFilterValues}
              setStatusTypes={setStatus}
              onFileSelect={requestAlarmManagerFile}
              onUpdateData={requestAlarmManagerTable}
              fileStatus={exportFileStatus}
              headerHeight={42}
              isDataLoading={globalLoading}
            />
          )}
      </Content>
    </Main>
  )
}

AlarmsManager.propTypes = {
  tree: pt.objectOf(pt.object),
  data: pt.arrayOf(pt.object),
  eventsCount: pt.number,
  eventData: pt.arrayOf(pt.object),
  tableStatus: pt.string,
  exportFileStatus: pt.string,
  eventTypes: pt.arrayOf(pt.string),
  eventStatus: pt.arrayOf(pt.string),
  aliasId: pt.string,
  selectedElement: pt.objectOf(pt.object),
  dates: pt.arrayOf(pt.string),
  node: pt.shape({
    name: pt.string,
  }),
  card: pt.shape({
    data: pt.object,
    requestStatus: pt.string,
  }),
  telemetry: telemetryArrayProps,

  setSelectedElement: pt.func,
  setEventTypes: pt.func,
  setStatusTypes: pt.func,
  setNode: pt.func,
  setDatesRange: pt.func,
  requestAlarmManagerTable: pt.func,
  requestAlarmManagerFile: pt.func,
}
AlarmsManager.defaultProps = {
  tree: {},
  data: [],
  eventsCount: 0,
  eventData: [],
  selectedElement: null,
  tableStatus: '',
  exportFileStatus: '',
  eventTypes: [],
  eventStatus: [],
  aliasId: null,
  dates: {},
  node: {},
  telemetry: [],
  card: {
    data: {},
    requestStatus: null,
  },
  requestStatus: REQUEST_STATUSES.NOT_REQUESTED,

  setSelectedElement: noop,
  setEventTypes: noop,
  setStatusTypes: noop,
  setNode: noop,
  setDatesRange: noop,
  requestAlarmManagerTable: noop,
  requestAlarmManagerFile: noop,
}

export default AlarmsManager
