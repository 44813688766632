import { GET_ALARMS_OBJECT } from './index';

export const requestGetAlarmsObject = (payload) => ({
    type: GET_ALARMS_OBJECT.REQUEST,
})

export const successGetAlarmsObject = (payload) => ({
    type: GET_ALARMS_OBJECT.SUCCESS,
    payload: payload,
})

export const errorGetAlarmsObject = (payload) => ({
    type: GET_ALARMS_OBJECT.ERROR,
    payload: payload,
})