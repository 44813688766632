import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import moment from 'moment/moment'
import {
  GET_PPR_TABLE,
  PPR_TABLE,
  SET_STATUS,
  SET_DATES_RANGE,
} from '@/store/actions/Ppr'
import { PPR_TABLE_ENDPOINT } from '@/constants/apiRoutes'
import  { getSortField }  from '@/helpers/getSortField'
import {
  successPprTable
} from '@/store/actions/Ppr/getTable'
import { getSelectedNode } from '@/store/selectors/appSettings'
import {
  getTableParameters,
  getYear,
  getStatus,
  getDatesRange
} from '@/store/selectors/ppr'
import { setGlobalLoading } from '@/store/actions/appSettings'

function* getPPRTableSaga() {
  try {
    const { start, end } = yield select(getDatesRange)
    const node = yield select(getSelectedNode)
    const year = yield select(getYear)
    if (!(start || end) || !node.id ) {
      yield put(successPprTable({data: []}))
      return
    }
    const tableParameters = yield select(getTableParameters)
    const status = yield select(getStatus)
    const sortField = getSortField(tableParameters.sort.field)
    yield put(setGlobalLoading(true))
    const response = yield request({
      url: PPR_TABLE_ENDPOINT({
        params: {
          pageNumber: tableParameters.page - 1,
          pageSize: tableParameters.perPage,
          parentId: node.id,
          sort: [`${sortField}: ${tableParameters.sort.direction}, details.factDate: ASC`],
          search: tableParameters.requestQuery || '',
          year,
          status,
          fromDate: start && moment(start).format('DD.MM.YYYY'),
          toDate: end && moment(end).format('DD.MM.YYYY')
        },
      }),
      method: 'get',
    })

    if (response.data) {
      const {
        content,
        pageable,
      } = response.data

      const res = content.map((element) => {
      const { details } = element
      const response = []
      details.forEach((detail) => {
        if (!details.filter((el) => el.originalInstallationType.includes('Пункты')).length && detail.installationType === 'CONTROL_CUPBOARD') {
          response.push(detail)
        }
        if (!details.filter((el) => el.originalInstallationType === 'Светильники').length && detail.installationType === 'Светильник') {
          response.push(detail)
        }
        if (!details.filter((el) => el.originalInstallationType === 'Опоры').length && detail.installationType === 'Опора') {
          response.push(detail)
        }
        if (detail.originalInstallationType === 'Светильники' && !detail.factDate) {
          const light = details.filter((el) => el.originalInstallationType === 'Светильник')[0]
          response.push({
            ...detail,
            factDate: details.find((el) => el.originalInstallationType === 'Светильник')?.factDate ?? null
          })
          details.filter((el) => el.originalInstallationType === 'Светильник').forEach((el) => {
            if (el && (el.factDate !== light?.factDate)){
              response.push(el)
            }
          })
          return
        }
        if (detail.originalInstallationType === 'Светильники' && detail.factDate) {
          const light = details.filter((el) => el.originalInstallationType === 'Светильники')[0]
          if (light?.factDate === detail.factDate) {
            response.push({
              ...detail,
              factDate: details.find((el) => el.originalInstallationType === 'Светильник')?.factDate ?? null
            })
            details.filter((el) => el.originalInstallationType === 'Светильник').forEach((el) => {
              if ((el.factDate !== light?.factDate)){
                response.push(el)
              }
            })
          } else {
            details.filter((el) => el.originalInstallationType === 'Светильники').forEach((el) => response.push(el))
            response.push(detail)
          }
          return
        }
        if (detail.originalInstallationType === 'Опоры' && !detail.factDate) {
          const pole = details.filter((el) => el.originalInstallationType === 'Опора')[0]
          response.push({
            ...detail,
            factDate: details.find((el) => el.originalInstallationType === 'Опора')?.factDate ?? null
          })
          details.filter((el) => el.originalInstallationType === 'Опора').forEach((el) => {
            if (el && (el.factDate !== pole?.factDate)){
              response.push(el)
            }
          })
          return
        }
        if (detail.originalInstallationType === 'Опоры' && detail.factDate) {
          const pole = details.filter((el) => el.originalInstallationType === 'Опора')[0]
          if (pole?.factDate === detail.factDate) {
            response.push({
              ...detail,
              factDate: details.find((el) => el.originalInstallationType === 'Опора')?.factDate ?? null
            })
            details.filter((el) => el.originalInstallationType === 'Опоры').forEach((el) => {
              if (el.factDate !== pole?.factDate){
                response.push(el)
              }
            })
          } else {
            details.filter((el) => el.originalInstallationType === 'Опоры').forEach((el) => response.push(el))
            response.push(detail)
          }
          return
        }
        if (detail.installationType === 'LINE') {
          response.push(detail)
          return
        }
        if (detail.originalInstallationType.includes('Пункты') && !detail.factDate) {
          const shuno = details.filter((el) => el.originalInstallationType === 'ШУНО')[0]
          response.push({
            ...detail,
            factDate: details.find((el) => el.installationType === 'CONTROL_CUPBOARD')?.factDate
          })
          details.filter((el) => el.originalInstallationType === 'ШУНО').forEach((el) => {
            if (el && (el.factDate !== shuno?.factDate)){
              response.push(el)
            }
          })
          return
        }
        if (detail.originalInstallationType.includes('Пункты') && detail.factDate) {
          const shuno = details.filter((el) => el.originalInstallationType === 'ШУНО')[0]

          if (shuno?.factDate === detail.factDate) {
            response.push({
              ...detail,
              factDate: details.find((el) => el.installationType === 'CONTROL_CUPBOARD')?.factDate
            })
            details.filter((el) => el.originalInstallationType === 'ШУНО').forEach((el) => {
              if (el.factDate !== shuno?.factDate){
                response.push(el)
              }
            })
          } else {
            details.filter((el) => el.originalInstallationType === 'ШУНО').forEach((el) => response.push(el))
            response.push(detail)
          }
          return
        }
      })
      return {
        ...element,
        details: response,
        // details: response.length ? response : element.details,
      }
      
      })
      yield put(setGlobalLoading(false))
      yield put(successPprTable({
        data: res,
        totalElements: pageable.totalElements,
        totalPages: pageable.totalPages,
      }))
    }

  } catch (error) {
    console.log(error)
  }

  return null
}

export default function* root() {
  yield takeLatest(GET_PPR_TABLE.REQUEST, getPPRTableSaga)
  yield takeLatest(PPR_TABLE.SORT, getPPRTableSaga)
  yield takeLatest(PPR_TABLE.PER_PAGE, getPPRTableSaga)
  yield takeLatest(PPR_TABLE.PAGE, getPPRTableSaga)
  yield takeLatest(PPR_TABLE.REQUEST_QUERY, getPPRTableSaga)
  yield takeLatest(SET_STATUS, getPPRTableSaga)
  yield takeLatest(SET_DATES_RANGE, getPPRTableSaga)
}
