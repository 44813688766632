import { connect } from 'react-redux'
import {
  getWidgetsSettings,
} from '@/store/selectors/maps/widgetSettings'
import {
  updateWidgetSettings,
} from '@/store/actions/maps/widgetSettings'
import {
  setSelectedElement,
} from '@/store/actions/alarmManager'
import ObjectState from './component'

const mapStateToProps = (state) => ({
  settings: getWidgetsSettings(state),
})
const mapDispatchToProps = (dispatch) => ({
  updateWidgetSettings: (id, data) => dispatch(updateWidgetSettings(id, data)),
  setSelectedElement: (payload) => dispatch(setSelectedElement(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ObjectState)
