import React, { useState } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { FormattedMessage as Lang } from 'react-intl'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import memoWithName from '@/hocs/memoWithName'
import DropDown from '@/components/blocks/DropDown'
import {
    Status,
    IndicatorWrapper,
    DescriptionWrapper,
} from '@/components/widgets/StateIndicator/styles'
import CoreIcons from '@/components/icons/core'
import {
    DEBOUNCE_DELAY_HUDGE,
} from '@/constants/time'
import {
    IconContainer,
    Title,
    Alarms,
    StyledLink,
} from './styles'
import { MALACHITE, REGENT_GRAY } from '@/constants/styles/defaultTheme'
import {
    ALARMS_URL,
  } from '@/constants/routes'


const ControllerStatusIndicator = ({
  firstTitle,
  secondTitle,
  firstStatusSelector,
  secondStatusSelector,
  timestamp,
  className,
  refreshIntegration,
  asuId,
  objectIdOuter,
  installationType,
  alarmsObject,
  setSelectedElement,
}) => {
    const [playAnimation, setPlayAnimation] = useState()
    const [isAlarmsOpen, setIsAlarmsOpen]= useState(false)
    const toggleIsAlarmsOpen = () => {
        setIsAlarmsOpen(!isAlarmsOpen)
    }
    const refreshHandler = () => {
        refreshIntegration({ asuId, objectIdOuter, installationType })
        setPlayAnimation(true)
        debounce(() => {
            setPlayAnimation(false)
        }, DEBOUNCE_DELAY_HUDGE)()
    }

    const setAlarm = (value) => {
        setSelectedElement(value)
    }
    const renderAlarms = () => {
        return (
            <>
                {alarmsObject.map((alarm) => 
                <StyledLink to={ALARMS_URL} >
                    <Title padding alertState={alarm.alertState} onClick={() => setAlarm(alarm)}>
                        {alarm?.description}
                    </Title>
                </StyledLink>
                )}
            </>
        )
    }
    return (
        <IndicatorWrapper
            column
            small
            className={className}
        >
            <DescriptionWrapper>
                <Title status nowrap>
                    {firstTitle}
                    {' '}
                    <Status color={firstStatusSelector ? MALACHITE : REGENT_GRAY}>
                        {firstStatusSelector
                            ? <Lang id="menu.online" />
                            : <Lang id="tooltip.offline" />
                        }
                    </Status>
                    <PortalTooltip
                        title={(<Lang id="tooltip.refresh" />)}
                        renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                            <IconContainer
                                playAnimation={playAnimation}
                                ref={wrapperRef}
                                onMouseEnter={onMouseEnterHandler}
                                onMouseLeave={onMouseLeaveHandler}
                                onClick={refreshHandler}
                            >
                                <CoreIcons.SyncIcon />
                            </IconContainer>
                        )}
                    />
                </Title>
                {!!alarmsObject.length && 
                    <Title>
                       <Alarms>
                           <Title>
                           {'Описание событий:'}
                           </Title>
                           {alarmsObject.length === 1 ? 
                           <StyledLink to={ALARMS_URL}>
                             <Title alertState={alarmsObject[0].alertState} onClick={() => setAlarm(alarmsObject[0])}>
                                   {alarmsObject[0].description}
                             </Title> 
                           </StyledLink>  
                           :  
                               <DropDown
                                   icon
                                   title={'Посмотреть события'}
                                   content={renderAlarms()}
                                   isOpen={isAlarmsOpen}
                                   onClick={toggleIsAlarmsOpen}
                               />
                           }
                       </Alarms>
                   </Title>
                }
                <Title status nowrap>
                    {secondTitle}
                    {' '}
                    <Status color={secondStatusSelector ? MALACHITE : REGENT_GRAY}>
                        {secondStatusSelector
                            ? <Lang id="menu.online" />
                            : <Lang id="tooltip.offline" />
                        }
                    </Status>
                </Title>
                <Title disabled>
                    <Lang id="menu.refreshed" /><span>: </span>
                    {`${timestamp 
                        ? moment(timestamp).format('DD.MM.YYYY HH:mm') 
                        : <Lang id="menu.unknown" />
                    }`}
                </Title>
            </DescriptionWrapper>
        </IndicatorWrapper>
    )
}

ControllerStatusIndicator.propTypes = {
    status: pt.string,
    timestamp: pt.string,
    className: pt.string,
    refreshIntegration: pt.func,
}
ControllerStatusIndicator.defaultProps = {
    status: '',
    timestamp: '',
    className: '',
    refreshIntegration: noop,
}

export default memoWithName(ControllerStatusIndicator)
